import React from "react"
import { graphql } from "gatsby"
import { Container, Grid } from "@material-ui/core"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Intro from "../components/website/Intro"
import BlueSection from "../components/website/BlueSection"
import Parallax from "../components/website/Parallax"
import OneCol from "../components/website/one-col"
import Accordion from "../components/accordions/products"

import Footer from "../images/products/products-cta.jpg"
import blueBG from "../images/products/products-background.svg"

export default function Products({ data }) {
  return (
    <Layout>
      <SEO title="Products" />
      {data.allStrapiProducts.edges.map(({ node }) => (
        <>
          <Intro
            key={node.products_intro.id}
            title={node.products_intro.intro_title}
            text={node.products_intro.intro_description}
            img={node.products_intro.Intro_hero.publicURL}
          />
          <BlueSection
            key={node.products_bluesection.id}
            title={node.products_bluesection.Bluesection_title}
            description={
              node.products_bluesection.bluesection_title_description
            }
            order="right"
            intro={true}
            background={blueBG}
          />
          <div className="my-32">
            <Container>
              <OneCol
                key={node.products_onecol.id}
                title={node.products_onecol.onecol_title}
                color={node.products_onecol.onecol_color}
                description={node.products_onecol.onecol_description}
              />
              <Grid container className="flex items-center">
                <Grid item md={6} sm={12} className="mt-16">
                  <Accordion />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  className="flex justify-center accordion-img"
                >
                  <img
                    src={node.products_img.publicURL}
                    alt="MARATHON
MORTGAGE CORP."
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
          <Parallax
            title="Our rates are … great. <br/>It’s that simple."
            link="/rates#table"
            button="view rates"
            order="right"
            image={Footer}
          />
        </>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query Products {
    allStrapiProducts {
      edges {
        node {
          products_intro {
            id
            intro_title
            intro_description
            intro_color
            Intro_hero {
              publicURL
            }
          }
          products_bluesection {
            id
            Bluesection_title
            bluesection_title_description
          }
          products_onecol {
            id
            onecol_title
            onecol_description
            onecol_color
          }
          products_img {
            publicURL
          }
        }
      }
    }
  }
`
